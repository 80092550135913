export default [
  {
    title: 'Natural Beauty',
    name: 'Natural Beauty',
    text:
      '',
  },
  {
    title: 'Increased Vitality',
    name: 'Increased Vitality',
    text:
      "Kim's book is a treasure trove of valuable resources to aid your longevity. ~Isabel Fish (Director, The Isabel Fish Academy) ",
  },
  {
    title: 'Esteem and Appearance',
    name: 'Esteem and Appearance',
    text:
      '',
  },
  {
    title: 'Improved Health',
    name: 'Improved Health',
    text:
      '',
  },
  {
    title: 'Enhanced physique',
    name: 'Enhanced physique',
    text:
      '',
  },
  {
    title: 'Advanced Learning',
    name: 'Health Psychology Guide and Book',
    text:
      "If you're serious about wanting a healthier more fulfilling life you need to read Kim's highly researched and comprehensive book 'Health Psychology.' This book is a road map of tools, truths, and resources that will empower you to make major changes that will have a huge impact on your life, to live with optimum health and radically improve the quality of all areas of your life. ~Lance Strong (9th Degree Black Belt, Member of the New Zealand Martial Arts Hall of Fame) ",
  },
  {
    title: 'Life Balance and Connection',
    name: 'Life Balance and Connection',
    text:
      '',
  },
  {
    title: 'Empowerment',
    name: 'Empowerment',
    text:
      '',
  },
  {
    title: 'Exquisite taste',
    name: 'Exquisite taste',
    text:
      'Greater Purpose Consciously shop online or at our Sanctuary Stores, personally advance and grow through our Urban Sanctuaryservices, enjoy the bliss and serenity of our Master Sanctuary, engage with other conscious creatures on ourHigher Consciousness Network and ultimately prosper through ‘right livihood’ with our Plato Franchise Systemopportunity.',
  },
  {
    title: 'Clarity of Mind',
    name: 'Clarity of Mind',
    text:
      '',
  },
  {
    title: 'Greater Happiness',
    name: 'Greater Happiness',
    text:
      '',
  },
];
