import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = (theme) => ({
  root: {
    backgroundColor: 'white',
    width: '100%',
    maxWidth: '1920px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',  
    gap: '10px',  
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column', 
      gap: '15px', 
    },
  },
  rowRight: {
    justifyContent: 'flex-end', 
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center', 
      marginBottom: '30px',
    },
    [theme.breakpoints.down('960')]: {
      marginBottom: '15px',
    },
  },
  rowLeft: {
    justifyContent: 'flex-start',  
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center', 
      marginBottom: '30px',
    },
  },
  rowCenter: { 
    [theme.breakpoints.down('lg')]: {
      paddingRight: '10%', 
      justifyContent: 'center', 
      paddingRight: 0, 
      marginBottom: '30px',
    },
  },
  gridItem: {
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: '10px',
    flex: '0 0 auto', 
    height: '210px', 
    width: '33%', 
    [theme.breakpoints.down('lg')]: {
      width: '30%',  
    },
    [theme.breakpoints.down('md')]: {
      width: '30%',
      height: '180px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      height: 'auto',
      margin: 'auto',
    },
    '@media (min-width: 768px) and (max-width: 768px), (min-width: 820px) and (max-width: 820px)': {
      height: 'auto',
    },
  },
  imageContainer: {
    position: 'relative',
    height: '210px', 
    maxWidth: '100%',  
    width: '100%',     
    overflow: 'hidden', 
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('1440px')]: {
      height: '190px',
    },
    [theme.breakpoints.down(['md'])]: {
      height: '180px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '180px',
    },
    marginTop: '10px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    opacity: '0.9',
    filter: 'brightness(0.7)', 
  },
  textContainer: {
    color: 'black',
    fontSize: '20px',
    alignItems: 'center',
    width: 'auto',
    textAlign: 'left',
    marginTop: '-10px',
    marginLeft: '30px',
    [theme.breakpoints.down(1875)]: {
      width: '90%',
      height: '180px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '-40px',
      height: 'auto',
    },
  },
  content: { 
    color: 'white', 
    fontWeight: '700',
  },
  title: {
    textAlign: 'left',
    marginTop: '-10px',
    height: '32px',
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '30px',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '17px',
      marginTop:'10px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
      marginTop: '30px',
    },
  },
  textContent: {
    textAlign: 'left',
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    color: 'black',
    width: '100%', 
    maxWidth: '633px', 
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '40px',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
      marginTop: '20px',
    },
  },
  imgTitle: {
    [theme.breakpoints.down('631')]: {
      fontSize: '1.2rem',
    },
    position: 'absolute',
    top: '40%', 
    right: '10px', 
    textAlign: 'right',
    fontSize: '2rem',
    transform: 'translateY(-50%)',
    color: 'white',
    fontWeight: '700',
    cursor: 'pointer',
    opacity: '1',
    maxWidth: 'calc(100% - 40px)', 
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem', 
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem', 
    },
  },
  desc: {
    whiteSpace: 'normal',  
    overflow: 'hidden',   
    display: '-webkit-box',  
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 5,    
    textOverflow: 'ellipsis', 
  },
  imgDesc: {
    position: 'absolute',
    bottom: '20px', 
    right: '10px', 
    fontSize: '16px',
    maxWidth: 'calc(100% - 40px)',
    color: 'white',
    textAlign: 'right',
    width: '100%', 
    maxWidth: '328px', 
    fontWeight: '500px',
    opacity: '1',
    wordWrap: 'break-word', 
    overflowWrap: 'break-word', 
    hyphens: 'auto', 
    maxHeight: '60%', 
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3, 
    '-webkit-box-orient': 'vertical',
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      width: '60%'
    },
  }, 
  filterText: {
    textAlign: 'center',
    width: '100%',
    fontSize: '24px',
    fontWeight: 'bold',
    color: 'black',
    transition: 'opacity 0.3s ease', 
    opacity: 1, 
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  hidden: {
    opacity: 0, 
    pointerEvents: 'none', 
  },
  accordionSummary: {
    transition: 'margin-bottom 0.3s ease',
  },
  accordionSummaryExpanded: { 
    marginBottom: '-50px', 
    '& .MuiIconButton-edgeEnd': {
      marginTop: '-35px',
    }
  },
  accordionDetails: {
    padding: '0',  
  },
});

const dehyphenateStr = (str) => {
  let string = str;
  const newStr = string.replace(/and/g, '&').replace(/-+/g, ' ');
  return newStr.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

function FlexibleGridItem({ classes, item, onClick, isSelected }) {
  const Content = () => (
    <div
      className={item.image ? classes.content : classes. textContainer}
      style={{
        color: item.image ? 'white' : 'black', 
      }}
    >
      <Typography
        className={item.image ? classes.imgTitle: classes.title}
        style={
          isSelected
            ? {
                border: '1px solid white',
                borderRadius: 5,
                padding: '0px 4px',
                background: '#ffffff36',
              }
            : {}
        }
      >
        {item.image ? dehyphenateStr(item.name) : dehyphenateStr(item.name).toUpperCase()}
      </Typography>
      {item.description && (
        <Typography variant="body2" className={item.image ? classes.imgDesc: classes.desc}>{item.description}</Typography>
      )}
    </div>
  );

  return (
    <Box
      className={classes.gridItem}
      onClick={item.image ? onClick : null}
      style={{
        maxWidth: item.imageWidth,
      }}
  >

    {item.image ? (
      <div className={classes.imageContainer} style={{ maxWidth: item.imageWidth }}>
          <img alt={item.name} src={item.image} className={classes.image} />
          <Content />
      </div>
    ) : (
      <Box className={classes.textContent}>
        <Content />
      </Box>
    )}
  </Box>
  );
}

function FlexibleGrid(props) {
  const { classes, filters, setFilters, bannerFilterData } = props;
  const [expanded, setExpanded] = useState(false);

  const handleChange = (tag, value) => {
    setFilters({
      ...filters,
      tag2: null,
      [tag]: value,
    });
  };

   const firstRowItems = bannerFilterData.slice(0, 3); 
   const secondRowItems = bannerFilterData.slice(3, 6); 
   const thirdRowItems = bannerFilterData.slice(6); 
 
  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      style={{maxWidth: '1920px', margin: 'auto'}}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"
        className={`${classes.accordionSummary} ${expanded ? classes.accordionSummaryExpanded : ''}`}
      >
      <Typography className={`${classes.filterText} ${expanded ? classes.hidden : ''}`}>Options</Typography>
    </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
      <div className={classes.root}>
        <div className={`${classes.row} ${classes.rowRight}`}>
          {firstRowItems.map((item, index) => (
            <FlexibleGridItem
              key={index}
              classes={classes}
              item={item}
              onClick={() => handleChange('tag1', item.name)}
              isSelected={filters.tag1 === item.name}
            />
          ))}
        </div>

        <div className={`${classes.row} ${classes.rowLeft}`}>
          {secondRowItems.map((item, index) => (
            <FlexibleGridItem
              key={index + firstRowItems.length}
              classes={classes}
              item={item}
              onClick={() => handleChange('tag1', item.name)}
              isSelected={filters.tag1 === item.name}
            />
          ))}
        </div>

        <div className={`${classes.row} ${classes.rowCenter}`}>
          {thirdRowItems.map((item, index) => (
            <FlexibleGridItem
              key={index + firstRowItems.length + secondRowItems.length}
              classes={classes}
              item={item}
            />
          ))}
        </div>
      </div>
    </AccordionDetails>
  </Accordion>
   );
}

export default withStyles(styles)(FlexibleGrid);