import React, { useEffect, useState } from 'react';
import CommonLayout from '../commonLayout/index';
import { useSelector, useDispatch } from 'react-redux';
import {
  addToMealPlan,
  setSelectedMealPlan,
  checkout,
  removeFromCart,
} from '~/store/actions';
import { useLocation, useHistory } from 'react-router-dom';

import { cat1ValsAndkeys, cat2ValsAndkeys, cat4ValsAndkeys } from '@visionforhumanity/ss-static/data/recipeData';
import { allergens, preferences } from '@visionforhumanity/ss-static/data/allergyData';
import { openLoginModal } from '~/utils/commonFunc';
import MealPlanner from '../Detail/Sections/MealPlanner';
import { useCreateUpdateMealPlanMutation, useFetchMealPlans } from 'shared/react-query/hooks';
import bannerData from './bannerData';

const recipeSortAttributes = [
  {
    key: 'protPercent',
    name: 'Protein %',
  },
  {
    key: 'a',
    name: 'Vitamin A',
  },
];

const modifiedCat1ValsAndkeys = [
  ...cat1ValsAndkeys,
  {
    key: null,
    name: null,
    subCat: [
      cat1ValsAndkeys[0].subCat[0],
      cat1ValsAndkeys[0].subCat[1],
      cat1ValsAndkeys[0].subCat[2],
      cat1ValsAndkeys[0].subCat[3],
      cat1ValsAndkeys[1].subCat[1],
    ],
  },
];

const bannerFilterData = bannerData.map((element) => {
  return { ...element, ...cat1ValsAndkeys.find((ele) => ele.key === element.key) };
});

const LifeforcefoodProductListing = (props) => {
  const selectedMealPlan = useSelector(({ mealPlanner }) => mealPlanner.selectedMealPlan);
  const mealPlans = useFetchMealPlans();
  const createUpdateMealPlan = useCreateUpdateMealPlanMutation();
  const dispatch = useDispatch();
  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [minimize, setMinimize] = useState(false);

  const handleCreateAMealPlan = async (values) => {
    const newMealPlan = await createUpdateMealPlan.mutateAsync({
      mealPlan: { name: values.name },
    });
    return newMealPlan;
  };

  return (
    <div>
      <CommonLayout
        bannerFilterData={bannerFilterData}
        modifiedCat1ValsAndkeys={modifiedCat1ValsAndkeys}
        recipeSortAttributes={recipeSortAttributes}
        allergens={allergens}
        preferences={preferences}
        cat2ValsAndkeys={cat2ValsAndkeys}
        cat4ValsAndkeys={cat4ValsAndkeys}
        collectionHandle={'Life-force-food'}
        onClickAddToMealPlan={(item) => {
          return dispatch(addToMealPlan(item))
            .then(() => {
              setMinimize(true);
            })
            .catch((error) => {
              if ((error = 'notLoggedIn')) {
                openLoginModal(history, location);
              }
            });
        }}
        pathname="Life Force Food"
      />
      <div
        style={{
          background: 'white',
          position: 'fixed',
          bottom: 0,
          width: '100%',
          zIndex: 99,
        }}
      >
        <MealPlanner
          minimize={minimize}
          setMinimize={(val) => setMinimize(val)}
          selectedMealPlan={selectedMealPlan}
          mealPlans={mealPlans.data ?? []}
          removeOnClick={(itemID, docLocation) => {
            // console.log(itemID, docLocation);
            dispatch(removeFromCart(itemID, `mealPlans/${docLocation}`));
          }}
          checkoutOnClick={(cartArr) => {
            if (!!cartArr.length) {
              return dispatch(checkout(cartArr));
            } else {
              return null;
            }
          }}
          onClickCreateMealPlan={handleCreateAMealPlan}
          onClickSelectMealPlan={(selectedMealPlan) => {
            return dispatch(setSelectedMealPlan(selectedMealPlan));
          }}
        />
      </div>
    </div>
  );
};

export default LifeforcefoodProductListing;
