import React, { useEffect, useState } from "react";
import person from "shared/assets/page/account/person.png";
import giftcard from "shared/assets/page/account/giftcard.png";
import share from "shared/assets/page/account/share.png";
import lockOpen from "shared/assets/page/account/lock_open.png";
import satisfied from "shared/assets/page/account/very_satisfied.png";
import list from "shared/assets/page/account/list.png";
import deliveryBox from "shared/assets/page/account/deliverybox.png";
import network from "shared/assets/page/account/network.png";
import addCircle from "shared/assets/page/account/addcircle.png";
import { Edit } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import { useFetchOrderHistory } from "shared/react-query/hooks";
import CustomerOrdersTable from "./CustomerOrdersTable.js";
import VisionaireNetworkTable from "./VisionaireNetworkTable.js";
import VisionaireNetworkTable2 from "./VisionaireNetworkTable2.js";
import AccordionDetails from "./AccountDetails.js.js";
import { useAuth } from 'shared/auth';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { SUBSCRIPTIONS_DATA } from "./SubscriptionsData.js";
import { updateProfile } from "../../react-query/strapiAPI.js";

export default function AccountTabsContent() {
  const { auth, profileData, logout } = useAuth();
  const history = useHistory();

  const [componentToDisplay, setComponentToDisplay] = useState("Account Detail");
  const { data: orderHistoryData } = useFetchOrderHistory({
    token: auth.token,
  });
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    if (profileData) {
      if (profileData.firstTimeUser === true || profileData.firstTimeUser === null) {
        setComponentToDisplay("wheel");
        updateProfile({
          ...profileData,
          firstTimeUser: false
        }, auth.token);
      }

      let initialSubscriptions = [];
      SUBSCRIPTIONS_DATA.filter((store) => store.store.state === "enabled")
        .map((store) => store.subscriptions.map((sub) => sub.code))
        .flat()
        .forEach((sub) => {
          if (profileData[sub] === true) {
            initialSubscriptions.push(sub);
          }
        });
      setSubscriptions(initialSubscriptions);
    }
  }, [profileData]);

  const handleListItemClick = (listItem) => {
    setComponentToDisplay(listItem);
  };

  const handleSubscriptionsChange = (subscriptions) => {
    setSubscriptions(subscriptions);

    const subList = SUBSCRIPTIONS_DATA.filter((store) => store.store.state === "enabled")
      .map((store) => store.subscriptions.map((sub) => sub.code))
      .flat();

    const newProfileData = {
      ...profileData,
    };

    subList.forEach((sub) => {
      newProfileData[sub] = subscriptions.includes(sub);
    });

    updateProfile(newProfileData, auth.token);
  };

  const renderSwitch = (componentToDisplay) => {
    switch (componentToDisplay) {
      case "Account Detail":
        return <AccordionDetails />;

      case "Advance Order":
        return <CustomerOrdersTable data={orderHistoryData ?? []} />;
      case "My Order":
        return <CustomerOrdersTable data={orderHistoryData ?? []} />;

      case "Visionaire Network":
      case "wheel":
        return <VisionaireNetworkTable display={handleListItemClick}
          subscriptions={subscriptions} onSubscriptionsChange={handleSubscriptionsChange} />;

      case "grid":
        return <VisionaireNetworkTable2 display={handleListItemClick}
          subscriptions={subscriptions} onSubscriptionsChange={handleSubscriptionsChange} />;
      default:
        return <AccordionDetails />;
    }
  };

  const handleLogout = () => {
    logout();
    history.push('/');
  };

  return (
    <div className="tab-wraper">
      <Grid container>
        <Grid item md={3} className="no-pad">
          <div className="profile-tab">
            <ul>
              <li
                className={
                  componentToDisplay === "Account Detail"
                    ? "tab-active"
                    : ""
                }
                onClick={() => handleListItemClick("Account Detail")}
              >
                <img src={person} />
                <span>Account Detail</span>
              </li>
              <li>
                <img alt="not found" src={giftcard} />
                <span>Visionaire Rewards</span>
              </li>
              <li
                className={
                  componentToDisplay === "Visionaire Network"
                    ? "tab-active"
                    : ""
                }
                onClick={() => handleListItemClick("Visionaire Network")}
              >
                <img alt="not found" src={share} />
                <span>Visionaire Network</span>
              </li>

              <li>
                <img alt="not found" src={lockOpen} />
                <span>Change Password</span>
              </li>
              <li>
                <Edit style={{ marginRight: 12 }} />
                <span>Create Detail</span>
              </li>
              <li>
                <img alt="not found" src={satisfied} />
                <span>About Me</span>
              </li>
              <li
                className={
                  componentToDisplay === "My Order" ? "tab-active" : ""
                }
                onClick={() => handleListItemClick("My Order")}
              >
                <img alt="not found" src={list} />
                <span>My Order</span>
              </li>
              <li
                className={
                  componentToDisplay === "Advance Order" ? "tab-active" : ""
                }
                onClick={() => handleListItemClick("Advance Order")}
              >
                <img alt="not found" src={deliveryBox} />
                <span>Advance Order</span>
              </li>
              <li
                className={
                  componentToDisplay === "participate" ? "tab-active" : ""
                }
                onClick={() => handleListItemClick("participate")}
              >
                <img alt="not found" src={network} />
                <span>Participate</span>
              </li>
              <li>
                <img alt="not found" src={addCircle} />
                <span>Create Profile</span>
              </li>
              <li onClick={handleLogout}>
                <img src={person} />
                <span>Logout</span>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item md={9} className="no-pad">
          <div className="profile-content">
            <h2>{(componentToDisplay === 'wheel' || componentToDisplay === 'grid') ? 'VISIONAIRE NETWORK' : componentToDisplay.toUpperCase()}</h2>
            <Grid container>{renderSwitch(componentToDisplay)}</Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
