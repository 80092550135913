// @ts-check
import { Card, CardContent, CardMedia, IconButton, makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '200px',
    maxWidth: '200px',
    height: 'auto',
    margin: theme.spacing(1),
    flexBasis: '100%',
    flexShrink: 0,
    position: 'relative',
  },
  cardMedia: {

  },
}));

/**
 * @param {Object} props
 * @param {Object} props.recipe
 * @param {Function} props.onRemove
 */
export default function MealPlanItem(props) {
  const classes = useStyles();
  const { recipe, onRemove } = props;
  const imgSrc = recipe?.images?.edges[0]?.node?.src;
  const productTitle = recipe?.title;

  return (
    <Card className={classes.root}>
      <CardMedia
        component="img"
        alt="product image"
        image={imgSrc}
        title="Product image"
        className={classes.cardMedia}
      />
      <CardContent>
        {productTitle && (
          <Typography style={{ color: '#9aa200', fontSize: 13, fontFamily: 'Lato', textAlign: 'center' }}>
            {productTitle}
          </Typography>
        )}
      </CardContent>
      <IconButton onClick={() => onRemove(recipe.id)} style={{
        position: 'absolute',
        top: 4,
        right: 4,
        padding: 4,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      }}
      >
        <ClearIcon />
      </IconButton>
    </Card>

  )
}
