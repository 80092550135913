import React from "react";

import { AiFillPicture } from "react-icons/ai";
import { BsFillGrid3X3GapFill } from "react-icons/bs";

import sanctuary_store from "shared/assets/visionaire-network/sanctuary_store.png";
import master_sanctuary from "shared/assets/visionaire-network/master_sanctuary.jpeg";
import urban_sanctuary from "shared/assets/visionaire-network/urban_sanctuary.png";
import metawheel from "shared/assets/visionaire-network/metawheel.jpg";
import visionforhumanity from "shared/assets/visionaire-network/visionforhumanity.jpg";
import biosphericallycorrect from "shared/assets/visionaire-network/biosphericallycorrrect.png";
import visionproducts from "shared/assets/visionaire-network/visionproducts.jpeg";
import "./visionairenetwork2.css";
import { SUBSCRIPTIONS_DATA } from "./SubscriptionsData";

const STORES_DATA = [
  {
    "code": "sanctuary_store",
    "img": sanctuary_store
  },
  {
    "code": "urban_sanctuary",
    "img": urban_sanctuary
  },
  {
    "code": "master_sanctuary",
    "name": "Master Sanctuary",
    "img": master_sanctuary
  },
  {
    "code": "metawheel",
    "name": "Meta Wheel",
    "img": metawheel
  },
  {
    "code": "health-creator",
    "name": "Health Creator",
    "img": metawheel
  },
  {
    "code": "visionforhumanity",
    "name": "Vision For Humanity",
    "img": visionforhumanity
  },
  {
    "code": "biosphericallycorrect",
    "name": "Biospherically Correct",
    "img": biosphericallycorrect,
  },
  {
    "code": "visionproducts",
    // "name": "Sanctuary Store",
    "img": visionproducts
  },
  {
    "code": "2nd-genome",
    "name": "2nd Genome",
    "img": metawheel
  },
  {
    "code": "hcn",
    "name": "H C N",
    "img": metawheel
  },
];

const VisionaireNetworkTable2 = ({ display, subscriptions, onSubscriptionsChange }) => {

  const handleOnCheckboxChange = (subscriptionCode) => {
    let newSubscriptions = [];
    if (subscriptions.includes(subscriptionCode)) {
      newSubscriptions = subscriptions.filter((item) => item !== subscriptionCode);
    }
    else {
      newSubscriptions = [...subscriptions, subscriptionCode];
    }

    onSubscriptionsChange(newSubscriptions);
  };

  return (
    <div className="form-wrapper">
      <div className="viewOptCont">
        <AiFillPicture
          className="optIcon"
          onClick={() => display("wheel")}
          style={{ color: "#999" }}
        />
        <BsFillGrid3X3GapFill
          className="optIcon"
          onClick={() => display("wheel")}
        />
      </div>

      <div className="meta-cont">
        {STORES_DATA.map((storeDetails, storeIndex) => {
          const store = SUBSCRIPTIONS_DATA.find((subData) => storeDetails.code === subData.store.code);
          return (
            <form key={storeIndex} className="form-cont">
              <div className="form-header">
                <img
                  className="form-logos"
                  src={storeDetails.img}
                  alt="sanctuary_store"
                />
                {storeDetails.name && <h4 className="form-heading">{storeDetails.name}</h4>}
              </div>

              {store.subscriptions.map((subscription, subIndex) => (
                <div key={`${storeIndex}-${subIndex}`} className="list">
                  <label className="custom-checkbox">
                    <input type="checkbox" onChange={() => handleOnCheckboxChange(subscription.code)}
                      checked={subscriptions.includes(subscription.code)}
                    />
                    <span className="helping-el"></span>
                    <span className="label-text">{subscription.name}</span>
                  </label>
                </div>
              ))}

              <div className="review-btn list">
                <a href="#">Review Visionare Details {">>"}</a>
              </div>
            </form>
          )
        })}

      </div>
    </div>
  );
};

export default VisionaireNetworkTable2;
