import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const AccountDetails = () => {
  return (
    <>
      <Col sm={12} md={12} className="no-pad">
        <div className="form-wrapper">
          <div className="form-sec">
            <label>Email :</label>
            <p>av@gmail.com</p>
          </div>
          <div className="form-sec">
            <label>Firstname :</label>
            <input type="text" placeholder="Enter Firstname" />
          </div>
          <div className="form-sec">
            <label>Lastname :</label>
            <input type="text" placeholder="Enter Lastname" />
          </div>
          <div className="form-sec">
            <label>Contact :</label>
            <input type="text" placeholder="Enter Contact" />
          </div>
          <div className="form-sec">
            <label>Address :</label>
            <input type="text" placeholder="Enter Address" />
          </div>
          <button>Update</button>
        </div>
      </Col>
    </>
  );
};

export default AccountDetails;
