import foodBannerImg from '~/assets/images/page/lifeforcefood/categoryFood.png';
import drinksBannerImg from '~/assets/images/page/lifeforcefood/categoryDrinks.png';
import dressingsBannerImg from '~/assets/images/page/lifeforcefood/categoryDressings.png';
import dessertsBannerImg from '~/assets/images/page/lifeforcefood/categoryDesserts.png';
import brochuresBannerImg from '~/assets/images/page/lifeforcefood/categoryBrochures.png';
import systemsBannerImg from '~/assets/images/page/lifeforcefood/categorySystems.png';
import videosBannerImg from '~/assets/images/page/lifeforcefood/categoryVideos.png';

const bannerData = [
  {
    name: 'Life Force Food',
    description: 'Life Force Food supported by our Health Creator App / Vision, Biospherically Correct, natural life style products / tech testing / and advancement guides for increased health span',
  },
  {
    name: 'food',
    image: foodBannerImg,
    imageWidth: '552px',
    description: 'with the complete life force of the organic plants still 100% intact, fully functional and bio-available.',
  },
  {
    name: 'drinks',
    image: drinksBannerImg,
    imageWidth: '523px',
    description: 'with the complete life force of the organic plants still 100% intact, fully functional and bio-available.',
  },
  {
    name: 'condiments',
    image: dressingsBannerImg,
    imageWidth: '537px',
    description: 'with the complete life force of the organic plants still 100% intact, fully functional and bio-available.',
  },
  {
    name: 'desserts',
    image: dessertsBannerImg,
    imageWidth: '538px',
    description: 'with the complete life force of the organic plants still 100% intact, fully functional and bio-available.',
  },
  {
    name: 'Philosophy of Products',
    description: 'Vestibulum blandit viverra convallis. Pellentesque ligula urna, fermentum ut semper in, tincidunt nec dui. Morbi mauris lacus, consequat eget justo in, semper gravida enim. Donec ultrices varius ligula. Ut non pretium augue. Etiam non rutrum metus. In varius sit amet lorem tempus sagittis. Cras sed maximus enim, vel ultricies tortor.',
  },
  {
    name: 'brochures',
    image: brochuresBannerImg,
    imageWidth: '630px',
  },
  {
    name: 'systems',
    image: systemsBannerImg,
    imageWidth: '630px',
  },
  {
    name: 'videos',
    image: videosBannerImg,
    imageWidth: '630px',
  }
];

export default bannerData;