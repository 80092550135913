import {GETPRODUCTS, CART, SELECTEDMEALPLAN, MEALPLANSARR} from '../action-types';
import {
  shopifyPOST,
  productObj,
  loggedInCheck,
  setSSCheckoutID,
  getSSCheckoutID,
  addItemToCart,
  shopifyIDsArrToStr,
  getMealPlans,
  cartItemShopify,
  getMealPlansShopifyDetails,
  removeItemFromCart,
} from '~/utils/commonFunc';
import algoliasearch from 'algoliasearch';
import config from 'shared/config/config';
import {getShopifyAccessToken} from './AuthActions';

const client = algoliasearch(config.algoliaConfig.applicationID, config.algoliaConfig.searchAPIKey);

// export const getProducts = (filter1, filter2, order = 'desc', orderBy = `total.createdOn`, showTrash = false) => {
//   const requestOptions = (ids) => ({
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       'X-Shopify-Storefront-Access-Token': '40fd3b933a64830e410ca4872d095cf0',
//     },
//     body: JSON.stringify({
//       query: `{
//         nodes(ids: [${ids}]) {
//            ... on Product {
//             id
//             images(first: 1) {
//               edges {
//                 node {
//                   originalSrc
//                 }
//               }
//             }
//             title
//             priceRange {
//               minVariantPrice {
//                 amount
//               }
//             }
//           }
//         }
//       }
//       `,
//     }),
//   });
//   return (dispatch, getState, {getFirebase, getFirestore}) => {
//     const firestore = getFirestore();
//     const query = firestore
//       .collection('recipes')
//       .where('trash', '==', showTrash)
//       // .where('cat2', '!=', 1)
//       .orderBy(orderBy, order)
//       .limit(10);

//     const arr = getState().products.productsArr.slice();
//     if (!!arr.length) {
//       return query
//         .startAfter(arr[arr.length - 1])
//         .get()
//         .then((docSnapshots) => {
//           if (!docSnapshots.docs.length) {
//             // return {message: messages.nothingMoretoLoad};
//           }
//           docSnapshots.docs.forEach((element) => {
//             arr.push(element);
//           });
//           console.log(arr, 'foodItems0');
//         })
//         .catch((error) => {
//           throw error;
//         });
//     } else {
//       return query
//         .get()
//         .then((docSnapshots) => {
//           if (!docSnapshots.docs.length) {
//             // return {message: messages.noIngredients};
//           }
//           docSnapshots.docs.forEach((element) => {
//             arr.push(element.data());
//           });
//           console.log(arr, 'foodItems0');
//           const shopifyIdsStr = arr
//             .map((e) => e)
//             .reduce((acc, cv) => {
//               return acc.concat('"' + cv.shopifyID + '",');
//             }, '');
//           return fetch('https://2nzd.myshopify.com/api/2021-01/graphql.json', requestOptions(shopifyIdsStr))
//             .then((res) => res.json())
//             .then((res) => {
//               arr.forEach((element) => {
//                 element.price = res.data.nodes.find(
//                   (element2) => element2.id == element.shopifyID
//                 ).priceRange.minVariantPrice.amount;
//                 element.imgs = res.data.nodes.find((element2) => element2.id == element.shopifyID).images.edges;
//                 element.imgs = element.imgs.map((element) => element.node.originalSrc);
//               });
//               dispatch({type: GETPRODUCTS, payload: arr});
//             });
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     }
//   };
// };

export const getProducts = (filterArr, indexName, searchText = '') => {
  console.log(filterArr, indexName, searchText, 'filterArr, indexName, searchText');
  const query = (ids) => `{
    nodes(ids: [${ids}]) {
      ... on Product {
        id
        title
        variants(first: 3) {
          edges {
            node {
              id
              price
              title
              image {
                originalSrc
              }
            }
          }
        }
        images(first: 1) {
          edges {
            node {
              originalSrc
            }
          }
        }
        priceRange {
          minVariantPrice {
            amount
          }
        }
      }
    }
  }
  
  `;
  console.log(filterArr, indexName, searchText, 'idx');
  const index = client.initIndex(`dev_recipes${indexName}`);
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    return index
      .search(searchText ?? '', {
        // tagFilters: filterArr,
        tagFilters: [],
      })
      .then(({hits}) => {
        console.log(hits, 'hits');
        const algoliaRes = hits;
        const shopifyIdsStr = shopifyIDsArrToStr(algoliaRes);
        return shopifyPOST(query(shopifyIdsStr)).then((res) => {
          // console.log(res, shopifyIdsStr, 'shopify');
          // console.log(productObj(algoliaRes[0], res.data.nodes[0]), 'shopiiis');
          const productArr = algoliaRes.map((element) =>
            productObj(
              element,
              res.data.nodes.find((element2) => element2.id == element.shopifyID)
            )
          );
          console.log(productArr, 'coollss');
          dispatch({type: GETPRODUCTS, payload: productArr});
          return productArr;
        });
      })
      .catch((err) => {
        console.log(err);
        throw JSON.stringify(err);
      });
  };
};

//You have to pass variant shopifyid
export const getProduct = (id) => {
  const query = (shopifyID) => `{
    node(id: "${shopifyID}") {
      ... on Product {
        id
        title
        variants(first: 3) {
          edges {
            node {
              id
              price
              title
              image {
                originalSrc
              }
            }
          }
        }
        images(first: 1) {
          edges {
            node {
              originalSrc
            }
          }
        }
        priceRange {
          minVariantPrice {
            amount
          }
        }
      }
    }
  }
  
  `;
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firestore = getFirestore();
    return firestore
      .collection('recipes')
      .doc(id)
      .get()
      .then((doc) => {
        console.log(doc);
        if (doc.exists) {
          doc.data();
          return shopifyPOST(query(doc.data().shopifyID)).then((res) => {
            return productObj(doc.data(), res.data.node);
            // console.log(res, 'shopify');
            // productObj.price = res.data.node.priceRange.minVariantPrice.amount;
            // productObj.imgs = res.data.node.images.edges;
            // productObj.imgs = productObj.imgs.map((element) => element.node.originalSrc);
            // return productObj;
          });
        } else {
          throw 'Product not found.';
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };
};

export const checkout = (cartArr) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    return dispatch(getShopifyAccessToken()).then((accessToken) => {
      return createShopifyCheckout(accessToken).then((checkoutID) => {
        const promises = cartArr.map((e) => addLineItem(checkoutID, e.shopifyID));
        return Promise.all(promises).then((res) => {
          return res[0].data.checkoutLineItemsAdd.checkout.webUrl;
        });
      });
    });
  };
};

const createShopifyCheckout = (accessToken) => {
  return shopifyPOST(`mutation MyMutation {
    checkoutCreate(
      input: {}
    ) {
      checkout {
        id
      }
    }
  }`)
    .then((res) =>
      shopifyPOST(`mutation MyMutation {
    checkoutCustomerAssociate(checkoutId: "${res.data.checkoutCreate.checkout.id}", customerAccessToken: "${accessToken}") {
      checkout {
        id
      }
    }
  }`)
    )
    .then((res) => res.data.checkoutCustomerAssociate.checkout.id);
};

const addLineItem = (checkoutId, productVariantId) => {
  return shopifyPOST(`mutation MyMutation {
    checkoutLineItemsAdd(checkoutId: "${checkoutId}",  lineItems: {quantity: 1, variantId: "${productVariantId}"}) {
      checkout {
        webUrl
      }
    }
  }
  `);
};

export const addToCart2 = (item, type) => {
  let obj = {};
  if (type === 'product') {
    obj = {shopifyID: item.shopifyID, quantity: 1, type: type};
  } else {
    obj = {
      type,
      quantity: 1,
    };
  }
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    return (
      getSSCheckoutID(firestore, firebase)
        .then((doc) => {
          if (doc.SSCheckoutID !== null) {
            return addLineItem(doc.SSCheckoutID, item.variants[0].id)
              .then((res) => {
                dispatch(getCheckout());
              })
              .catch((error) => {
                return setSSCheckoutID(firestore, firebase, null).then(() => {
                  dispatch(addToCart(item, type));
                });
              });
          } else {
            return dispatch(getShopifyAccessToken()).then((accessToken) => {
              return createShopifyCheckout(accessToken).then((res) => {
                return setSSCheckoutID(firestore, firebase, res).then(() => {
                  return addLineItem(res, item.variants[0].id).then((res) => console.log(res));
                });
              });
            });
          }
        })
        //   return firestore
        //     .collection('users')
        //     .doc(uid)
        //     .update({
        //       [`cart.${item.objectID}`]: obj,
        //     })
        //     .then((res) => console.log(res, 'DONE!!'));
        .catch((error) => {
          throw error;
        })
    );
  };
};

// export const addToCart = (item) => {
//   return (dispatch, getState, {getFirebase, getFirestore}) => {
//     const firestore = getFirestore();
//     const firebase = getFirebase();
//     return loggedInCheck(firebase)
//       .then((uid) => {
//         return addItemToCart(item.objectID, item.variants[0].id, 1, null, firestore, `users/${uid}`);
//       })
//       .catch((error) => {
//         throw error;
//       });
//   };
// };

export const addToCart = (item, qty = '') => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    return loggedInCheck(firebase)
      .then((uid) => {
        return addItemToCart(
          item.objectID,
          item.variantID ? item.variantID : item.variants[0].id,
          qty == '' ? 1 : qty,
          null,
          firestore,
          `users/${uid}`
        );
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const removeFromCart = (itemID, docLocation = '') => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    return loggedInCheck(firebase)
      .then((uid) => {
        console.log(uid);
        return removeItemFromCart(itemID, firestore, `users/${uid}/${docLocation}`);
      })
      .catch((error) => console.log(error, 'error'));
  };
};

export const cartListenerSubscribe = () => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    // console.log('subscribed');
    const firestore = getFirestore();
    const firebase = getFirebase();
    return loggedInCheck(firebase).then((uid) => {
      return firestore.doc(`users/${uid}`).onSnapshot((doc) => {
        return cartItemShopify(doc.data().cart ?? [], shopifyPOST).then((cart) => {
          dispatch({type: CART, payload: cart});
        });
      });
    });
  };
};

export const addToMealPlan = (item) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const selectedMealPlan = getState().mealPlanner.selectedMealPlan;
    const selectedItemCat = getState().mealPlanner.selectedItemCat;
    const firestore = getFirestore();
    const firebase = getFirebase();
    return loggedInCheck(firebase).then((uid) => {
      if (selectedMealPlan == null && selectedItemCat == null) {
        return new Promise((res, rej) => res(''));
      }
      item.itemCat = selectedItemCat;
      return addItemToCart(
        item.objectID,
        item.variants[0].id,
        1,
        item.itemCat,
        firestore,
        `users/${uid}/mealPlans/${selectedMealPlan}`,
        true
      );
    });
  };
};

export const setSelectedMealPlan = (str) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    dispatch({type: SELECTEDMEALPLAN, payload: str});
  };
};

export const getCheckout = () => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    return getSSCheckoutID(firestore, firebase).then((doc) => {
      if (doc.SSCheckoutID !== null) {
        return shopifyPOST(`{
    node(id: "${doc.SSCheckoutID}") {
      ... on Checkout {
        id
        lineItems(first: 250) {
          edges {
            node {
              quantity
              id
              title
              variant {
                price
                title
                image {
                  transformedSrc(maxWidth: 100)
                }
              }
            }
          }
        }
        webUrl
      }
    }
  }`).then((res) => {
          const obj = {...res.data.node};
          obj.lineItems = obj.lineItems.edges.map((ele) => ele.node);
          // dispatch({type: CHECKOUT, payload: obj});
        });
      } else {
        // dispatch({type: CHECKOUT, payload: null});
      }
    });
  };
};
