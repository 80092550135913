import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, IconButton} from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import {getPath} from '~/static/routes';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import OuterContainer from '../OuterContainer';
const useStyles = makeStyles((theme) => ({
  class0: {
    padding: '40px 0',
    [theme.breakpoints.down(450)]: {
      padding: '2px 0',
    },
  },
  class1: {
    width: '100%',
    margin: 0,
  },
  class2: {
    color: 'white',
    fontSize: '32px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    textAlign: 'center',
    [theme.breakpoints.down(450)]: {
      fontSize: '1.2rem',
    },
  },
  class3: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '1rem',
    fontFamily: 'Roboto',
  },
  class4: {
    color: 'white',
    fontSize: '32px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: 1.2,
    fontStretch: 'normal',
    letterSpacing: 1,
    [theme.breakpoints.down(450)]: {
      fontSize: '1.1rem',
    },
  },
  socialIcons: {
    width: '20px',
    height: '20px',
    color: '#9aa200',
    [theme.breakpoints.down(450)]: {
      width: '16px',
      height: '16px',
    },
  },
  class5: {
    maxWidth: 150,
    [theme.breakpoints.down(450)]: {
      maxWidth: 136,
    },
  },
  class6: {
    boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.20)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  class7: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '1rem',
    fontFamily: 'Roboto',
    width: '275px',
    height: '28px',
    padding: '10px',
  },
  class8: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '1rem',
    fontFamily: 'Roboto',
    padding: '10px',
    width: '227px',
    height: '28px',
  },
  class9: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: 'white',
    marginTop: '20px',
    width: '667px',
    height:'47px',
  },
  class10: {
    boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.20)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    height: '450px',
    maxWidth: '1920px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  class11: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '1rem',
    fontFamily: 'Roboto',
    marginTop: '100px',
  },
  class12: {
    color: 'white',
    fontSize: '32px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: 1.2,
    width: '456px',
    height: '64px',
    fontStretch: 'normal',
    letterSpacing: 1,
    [theme.breakpoints.down(450)]: {
      fontSize: '1.1rem',
    },
  },
  labelWithLine: {
    position: 'relative',
    display: 'inline-block',
    paddingTop: '10px', 
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '0%', 
      right: '85%', 
      borderTop: '2px solid white', 
    },
  },
  gridContainer: {
    [theme.breakpoints.down(532)]: {
      marginTop: '-40px',
    },
  },
  whiteBox: {
    border: '1px dashed rgba(255, 255, 255, 0.8)', 
    padding: '10px 20px', 
    marginTop: '20px',
    opacity: '1.6',
    borderRadius: '6px',
    width: '100%', 
    maxWidth: '711px',
  },
  whiteText: {
    color: 'white',
    fontWeight: 'normal',
    fontSize: '16px',
    maxWidth: '100%', 
    padding: '5px', 
    boxSizing: 'border-box',
  },
}));

const dehyphenateStr = (str) => {
  let string = str;
  const newStr = string.replace(/and/g, '&').replace(/-+/g, ' ');
  return newStr.charAt(0).toUpperCase() + newStr.slice(1);
};

export const Breadcrumbs = (props) => {
  const {Extension = () => <></>, url, location} = props;
  const classes = useStyles();

  const pathnames = location.pathname.split('/').filter((x) => x);

  const naturalHighsBar = ">> See Natural Highs Bar";
  const liveSaladBar = ">> See Live Salad Bar";

  if (pathnames.length == 1) {
    return (
      <OuterContainer
        style={{
          backgroundImage: `url(${url})`,
        }}
        className={classes.class6}
      >
        <Grid container className={clsx(classes.class0, classes.class1)} justify="center" alignItems="center">
          <Grid item xs={11}>
            <Typography className={classes.class2}>{dehyphenateStr(pathnames[0]).toUpperCase()}</Typography>
          </Grid>
          <Grid container className={classes.class1} item xs={12}>
            <Extension />
          </Grid>
        </Grid>
      </OuterContainer>
    );
  } else {
    return (
      <OuterContainer
        style={{
          backgroundImage: `url(${url})`,
        }}
        className={pathnames[1] === 'life-force-food' ? classes.class10 : classes.class6}
      >

        <Grid container spacing={2} className={clsx(classes.class1, classes.class0)}>
          <Grid item container xs spacing={2}>
            {pathnames.map((element, index) => {
              const to = `/${pathnames.slice(0, index + 1).join('/')}`;
              const label = dehyphenateStr(element);
              if (pathnames.length - 1 === index) {
                return (
                  pathnames[1] === 'life-force-food' ? (
                    <Grid key={element + index} item xs={12}>
                      <Typography className={clsx(classes.class11, classes.class12, classes.labelWithLine)}>
                        {label.toUpperCase()}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid key={element + index} item xs={12}>
                      <Typography className={clsx(classes.class3, classes.class4)}>
                        {label.toUpperCase()}
                      </Typography>
                    </Grid>
                  )
                );

              } else {
                  return (
                    pathnames[1] !== 'life-force-food' ? (
                      <Grid key={element + index} item>
                        <Link className={classes.class3} to={to}>
                          {label} &gt;
                        </Link>
                      </Grid>
                    ) : ''
                  )
                }
            })}
          </Grid>
          <Grid container item xs alignItems="center" className={classes.class5}>
            {/* <Grid item xs style={{textAlign: 'right'}}>
              <IconButton >
                <FacebookIcon className={classes.socialIcons} />
              </IconButton>
            </Grid>
            <Grid item xs style={{textAlign: 'right'}}>
              <IconButton>
                <TwitterIcon className={classes.socialIcons} />
              </IconButton>
            </Grid> */}
            <Grid item xs style={{textAlign: 'right'}}>
              <IconButton onClick={()=>{
                window.open('https://www.instagram.com/vfhsanctuaries/','_blank')
              }}>
                <InstagramIcon className={classes.socialIcons} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container className={classes.class1} item xs={12}>
            <Extension />
          </Grid>

          {pathnames[1] === 'life-force-food' ? (
            <Grid container direction="column" spacing={1} className={classes.gridContainer}>
            <Grid item>
              <Link className={classes.class7}>{naturalHighsBar}</Link>
            </Grid>
            <Grid item>
              <Link className={classes.class8}>{liveSaladBar}</Link>
          </Grid>
            </Grid>
            
          ) : ''}
      
          {/* <Grid style={{ border: '2px dashed rgba(0, 0, 0, 0.5)', backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '7px 25px 20px', marginTop: '20px', opacity: '0.5', borderRadius: '6px' }}> */}
          
          {pathnames[1] === 'life-force-food' ? (
            <Grid className={classes.whiteBox}>
              <Typography className={classes.whiteText}>
                Life Force Food supported by our Health Creator App / Vision, Biospherically Correct, natural life style products / tech testing / and advancement guides for increased health span.
              </Typography>
            </Grid>
          ) : ''}
          
        </Grid>
      </OuterContainer > 
    );
  }
};
