export const SUBSCRIPTIONS_DATA = [
  {
    "store": {
      "code": "sanctuary_store",
      "state": "enabled",
    },
    "subscriptions": [
      {
        "code": "sanctuaryStoreVisionaireSubscription",
        "name": "Visionaire"
      },
      {
        "code": "sanctuaryStoreReceiveMarketingSubscription",
        "name": "Receive Marketing"
      }
    ]
  },
  {
    "store": {
      "code": "urban_sanctuary",
      "state": "enabled",
    },
    "subscriptions": [
      {
        "code": "urbanSanctuaryVisionaireSubscription",
        "name": "Visionaire"
      },
      {
        "code": "urbanSanctuaryReceiveMarketingSubscription",
        "name": "Receive Marketing"
      }
    ]
  },
  {
    "store": {
      "code": "master_sanctuary",
      "state": "disabled",
    },
    "subscriptions": [
      {
        "code": "masterSanctuaryVisionaireSubscription",
        "name": "Visionaire"
      },
      {
        "code": "masterSanctuaryReceiveMarketingSubscription",
        "name": "Receive Marketing"
      }
    ]
  },
  {
    "store": {
      "code": "metawheel",
      "state": "disabled",
    },
    "subscriptions": [
      {
        "code": "metawheelVisionaireSubscription",
        "name": "Visionaire"
      },
      {
        "code": "metawheelReceiveMarketingSubscription",
        "name": "Receive Marketing"
      }
    ]
  },
  {
    "store": {
      "code": "health-creator",
      "state": "disabled",
    },
    "subscriptions": [
      {
        "code": "healthCreatorVisionaireSubscription",
        "name": "Visionaire"
      },
      {
        "code": "healthCreatorReceiveMarketingSubscription",
        "name": "Receive Marketing"
      }
    ]
  },
  {
    "store": {
      "code": "weight-shifter",
      "state": "disabled",
    },
    "subscriptions": [
      {
        "code": "weightShifterVisionaireSubscription",
        "name": "Visionaire"
      },
      {
        "code": "weightShifterReceiveMarketingSubscription",
        "name": "Receive Marketing"
      }
    ]
  },
  {
    "store": {
      "code": "visionforhumanity",
      "state": "disabled",
    },
    "subscriptions": [
      {
        "code": "visionforhumanityVisionaireSubscription",
        "name": "Visionaire"
      },
      {
        "code": "visionforhumanityReceiveMarketingSubscription",
        "name": "Receive Marketing"
      }
    ]
  },
  {
    "store": {
      "code": "biosphericallycorrect",
      "state": "disabled",
    },
    "subscriptions": [
      {
        "code": "biosphericallycorrectVisionaireSubscription",
        "name": "Visionaire"
      },
      {
        "code": "biosphericallycorrectReceiveMarketingSubscription",
        "name": "Receive Marketing"
      }
    ]
  },
  {
    "store": {
      "code": "visionproducts",
      "state": "disabled",
    },
    "subscriptions": [
      {
        "code": "visionproductsVisionaireSubscription",
        "name": "Visionaire"
      },
      {
        "code": "visionproductsReceiveMarketingSubscription",
        "name": "Receive Marketing"
      }
    ]
  },
  {
    "store": {
      "code": "2nd-genome",
      "state": "enabled",
    },
    "subscriptions": [
      {
        "code": "secondGenomeVisionaireSubscription",
        "name": "Become a Visionaire"
      },
      {
        "code": "secondGenomeReceiveMarketingSubscription",
        "name": "Receive Marketing"
      }
    ]
  },
  {
    "store": {
      "code": "hcn",
      "state": "enabled",
    },
    "subscriptions": [
      {
        "code": "hcnVisionaireSubscription",
        "name": "Become a Visionaire"
      },
      {
        "code": "hcnReceiveMarketingSubscription",
        "name": "Receive Marketing"
      }
    ]
  },
];