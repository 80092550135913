import React from 'react';
import CommonLayout from '../commonLayout/index';
import {useSelector, useDispatch} from 'react-redux';
import {getProducts} from '~/store/actions';

import foodBannerImg from '~/assets/images/page/lifeforcefood/categoryFood.png';
import drinksBannerImg from '~/assets/images/page/lifeforcefood/categoryDrinks.png';
import dressingsBannerImg from '~/assets/images/page/lifeforcefood/categoryDressings.png';
import dessertsBannerImg from '~/assets/images/page/lifeforcefood/categoryDesserts.png';
import specialisedOrganicCerealMixes  from '~/assets/images/page/lifeforcefood/unnamed-4.jpg';
import variousLegumes  from '~/assets/images/page/lifeforcefood/various-legumes.jpg';
import CoffeeTea  from '~/assets/images/page/lifeforcefood/coffee-tea-359814.webp';


import functionalPowdersHerbsAndSpices from '~/assets/images/page/lifeforcefood/bowls-with-condiments-table.jpg';
import {cat2ValsAndkeys, cat4ValsAndkeys} from '@visionforhumanity/ss-static/data/recipeData';
// import {allergens, preferences} from '@visionforhumanity/ss-static/data/allergyData';
const recipeSortAttributes = [
  {
    key: 'protPercent',
    name: 'Protein %',
  },
  {
    key: 'a',
    name: 'Vitamin A',
  },
];

const cat1ValsAndkeys = [
  {
    key: 'specialisedOrganicCerealMixes',
    name: 'Specialised Organic Cereal mixes',
    subCat: [],
  },
  {
    key: 'organicGrainsLegumesSeedsNutsAndSnacks',
    name: 'Organic grains, legumes, seeds, nuts and snacks',
    subCat: [],
  },
  {
    key: 'functionalPowdersHerbsAndSpices',
    name: 'Functional powders, herbs and spices',
    subCat: [],
  },
  {
    key: 'organicCoffeesAndTeas',
    name: 'Organic Coffees, teas and cacao',
    subCat: [],
  },
];

const modifiedCat1ValsAndkeys = [
  ...cat1ValsAndkeys,
  {
    key: null,
    name: null,
    subCat: [],
  },
];

const bannerData = [
  {
    key: 'specialisedOrganicCerealMixes',
    image: specialisedOrganicCerealMixes,
  },
  {
    key: 'organicGrainsLegumesSeedsNutsAndSnacks',
    image: variousLegumes,
  },
  {
    key: 'functionalPowdersHerbsAndSpices',
    image: functionalPowdersHerbsAndSpices,
  },
  {
    key: 'organicCoffeesAndTeas',
    image: CoffeeTea,
  },
];
const bannerFilterData = bannerData.map((element) => {
  return {...element, ...cat1ValsAndkeys.find((ele) => ele.key === element.key)};
});

const RawIngredientsProductListing = (props) => {
  return (
    <CommonLayout
      style={1}
      bannerFilterData={bannerFilterData}
      modifiedCat1ValsAndkeys={modifiedCat1ValsAndkeys}
      recipeSortAttributes={recipeSortAttributes}
      allergens={[]}
      preferences={[]}
      cat4ValsAndkeys={cat4ValsAndkeys}
      collectionHandle={'Raw-organic-ingredients'}
      onClickAddToMealPlan={() => {}}
      pathname="Raw Organic Ingredients"
    />
  );
};

export default RawIngredientsProductListing;
