import React from "react";
import { useState } from "react";
import "./account.scss";

import { AiFillPicture } from "react-icons/ai";
import { BsFillGrid3X3GapFill } from "react-icons/bs";

import metaBG from "shared/assets/visionaire-network/metaBG.png";
import sslogo from "shared/assets/visionaire-network/sslogo.png";
import uslogo from "shared/assets/visionaire-network/uslogo.png";
import mslogo from "shared/assets/visionaire-network/mslogo.png";
import mwlogo from "shared/assets/visionaire-network/mwlogo.png";
import hclogo from "shared/assets/visionaire-network/hclogo.png";
import wslogo from "shared/assets/visionaire-network/wslogo.png";
import vfhlogo from "shared/assets/visionaire-network/vfhlogo.png";
import bclogo from "shared/assets/visionaire-network/bclogo.png";
import vplogo from "shared/assets/visionaire-network/vplogo.png";
import sgnlogo from "shared/assets/visionaire-network/sgnlogo.png";
import hcnlogo from "shared/assets/visionaire-network/hcnlogo.png";
import { SUBSCRIPTIONS_DATA } from "./SubscriptionsData";
import { unstable_batchedUpdates } from "react-dom";
import { Box, Divider, Popover, Grid } from "@material-ui/core";

const STORES_DATA = [
  {
    "code": "sanctuary_store",
    "img": sslogo,
    "logo": "sslogo"
  },
  {
    "code": "urban_sanctuary",
    "img": uslogo,
    "logo": "uslogo"
  },
  {
    "code": "master_sanctuary",
    "img": mslogo,
    "logo": "mslogo"
  },
  {
    "code": "metawheel",
    "name": "Meta Wheel",
    "img": mwlogo,
    "logo": "mwlogo"
  },
  {
    "code": "health-creator",
    "name": "Health Creator",
    "img": hclogo,
    "logo": "hclogo"
  },
  {
    "code": "weight-shifter",
    "name": "Weight Shifter",
    "img": wslogo,
    "logo": "wslogo"
  },
  {
    "code": "visionforhumanity",
    "name": "Vision For Humanity",
    "img": vfhlogo,
    "logo": "vfhlogo"
  },
  {
    "code": "biosphericallycorrect",
    "name": "Biospherically Correct",
    "img": bclogo,
    "logo": "bclogo"
  },
  {
    "code": "visionproducts",
    "name": "Sanctuary Store",
    "img": vplogo,
    "logo": "vplogo"
  },
  {
    "code": "2nd-genome",
    "name": "2nd Genome",
    "img": sgnlogo,
    "logo": "sgnlogo"
  },
  {
    "code": "hcn",
    "name": "H C N",
    "img": hcnlogo,
    "logo": "hcnlogo"
  },
];

const VisionaireNetworkTable = ({ display, subscriptions, onSubscriptionsChange }) => {
  const [popup, setPopup] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const togglePopup = (e, storeCode) => {
    unstable_batchedUpdates(() => {
      if (popup) {
        setSubscriptionData({});
      }
      else {
        const sub = SUBSCRIPTIONS_DATA.find((subData) => storeCode === subData.store.code);
        setSubscriptionData(sub);
      }
      setPopup(!popup);

      setAnchorEl(e.current);
    });
  };

  const handleClickOutside = () => {
    if (popup) {
      setPopup(false);
      setSubscriptionData({});
    }
  };

  const handleOnCheckboxChange = (subscriptionCode) => {
    let newSubscriptions = [];
    if (subscriptions.includes(subscriptionCode)) {
      newSubscriptions = subscriptions.filter((item) => item !== subscriptionCode);
    }
    else {
      newSubscriptions = [...subscriptions, subscriptionCode];
    }
    onSubscriptionsChange(newSubscriptions);
  };

  const SubscriptionForm = () => {

    return (
      <form className={`meta-form-cont`}
        onClick={(e) => e.stopPropagation()}>
        {subscriptionData?.subscriptions?.map((subscription, index) => (
          <div key={index} className="list">
            <label className="custom-checkbox">
              <input type="checkbox" onChange={() => handleOnCheckboxChange(subscription.code)}
                checked={subscriptions.includes(subscription.code)} />
              <span className="helping-el"></span>
              <span className="label-text">{subscription.name}</span>
            </label>
          </div>
        ))}
        <Divider light />
        <Box px={4} py={2}>
          <a href="#">Review Visionare Details {">>"}</a>
        </Box>
      </form>
    )
  };

  return (
    <Grid className="form-wrapper" container>

      <div className="viewOptCont">
        <AiFillPicture className="optIcon" onClick={() => display('wheel')} />
        <BsFillGrid3X3GapFill
          className="optIcon"
          style={{ color: "#999" }}
          onClick={() => display('grid')}
        />
      </div>

      <Grid item md={12} className="meta-cont" onClick={handleClickOutside}>
        <div className="meta-wheel">
          <img className="metaBGImg" src={metaBG} alt="metaBG" />

          <div className="logoz-cont">
            {STORES_DATA.map((storeDetails, storeIndex) => {
              const imgRef = React.createRef();
              return (
                <a className={`logoz-link ${storeDetails.logo}`} key={storeIndex}
                  onClick={(e) => togglePopup(imgRef, storeDetails.code)}
                >
                  <img ref={imgRef} className="logoz" src={storeDetails.img} alt={storeDetails.logo} />
                </a>
              );
            }
            )}

            {popup &&

              <Popover
                anchorEl={anchorEl}
                open={popup}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <SubscriptionForm />

              </Popover>}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default VisionaireNetworkTable;


