import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = (theme) => ({
  class1: {
    boxSizing: 'border-box',
    // border: '1px red solid',
    width: '100%',
  },
  class2: {
    display: 'flex',
    boxSizing: 'border-box',
    // border: '2px blue solid',
    flex: 1,
    width: (props) => props.width || '75%',
    minWidth: 1000,
    margin: '0 auto',
    flexDirection: 'column',
    [theme.breakpoints.down(1024)]: {
      minWidth: '100%',
    },
  },
});

const OuterContainer = (props) => {
  const { classes, children, className, style, width } = props;
  return (
    <div className={clsx(classes.class1, className)} style={style}>
      <div className={classes.class2} style={{width: width ? width : '75%'}}>{children}</div>
    </div>
  );
};

export default withStyles(styles)(OuterContainer);
