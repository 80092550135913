import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import FormBuilder from 'shared/formBuilder';
import { selectExistingMealPlan } from 'shared/formStructures/mealPlan';

export const SelectExistingMP = (props) => {
  const { mealPlans, onClickSelectMealPlan } = props;
  const modifiedSelectExistingMealPlan = selectExistingMealPlan.slice();
  modifiedSelectExistingMealPlan.forEach((e) => {
    if (e.inputFieldProps.key == 'selectedMealPlan') {
      e.options = mealPlans.map((el) => {
        return { label: `${el.name}`, value: el.id };
      });
    }
  });
  return (
    <Grid container item xs={12} justify="center">
      <Grid xs={12} item style={{ textAlign: 'center' }}>
        <Typography variant='h3'>Select an existing meal plan</Typography>
      </Grid>
      <FormBuilder
        spacing={2}
        xs={12}
        justify="center"
        structure={modifiedSelectExistingMealPlan}
        onSubmit={(values) => {
          console.log(values);
          return onClickSelectMealPlan(values.selectedMealPlan);
        }}
      />
    </Grid>
  );
};