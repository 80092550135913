
import { Button, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import MealPlanItem from './MealPlanItem';
import ClearIcon from '@material-ui/icons/Clear';
import { useAuth } from 'shared/auth';
import { useAddProductToCart, useCreateUpdateMealPlanMutation, useFetchProductsByIds, useRemoveMealPlanMutation } from 'shared/react-query/hooks';
import { useCheckoutId } from '~/hooks/uselocalForage';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    border: '1px solid #ccc',
    gap: theme.spacing(2),
    flexBasis: 'auto',
  },
  buttons: {
    gap: theme.spacing(2),
    width: 'fit-content',
  },
  mealPlanItemWrapper: {
    // gap: theme.spacing(2),
    minHeight: '200px',
    overflow: 'auto',
  }
}));

/**
 * @param {Object} props
 * @param {import('shared/react-query/strapiAPI').MealPlan} props.mealPlan
 */
export default function MealPlan(props) {
  const { mealPlan } = props;

  const classes = useStyles();
  const { auth } = useAuth();
  const { checkoutId } = useCheckoutId();
  const addItemToCartMutation = useAddProductToCart();
  const createUpdateMealPlan = useCreateUpdateMealPlanMutation();
  const { data: productsByIdsData } = useFetchProductsByIds(mealPlan.recipes);
  const removeMealPlan = useRemoveMealPlanMutation();

  const handleRemoveMealPlan = async (mealPlanId) => {
    await removeMealPlan.mutateAsync({ mealPlanId, token: auth.token });
  };

  const handleAddAllMealPlannerItemsToCart = async () => {
    const lineItems = productsByIdsData?.map(product => product.variants?.nodes[0]?.id)
      .map(productVariantId => ({
        merchandiseId: productVariantId,
        quantity: 1,
      }));
    await addItemToCartMutation.mutateAsync({ cartId: checkoutId, lineItems });
  };

  const handleRemoveMealPlanItem = async (productId) => {
    await createUpdateMealPlan.mutateAsync({
      mealPlan: {
        ...mealPlan,
        recipes: mealPlan.recipes.filter(id => id !== productId),
      },
      token: auth.token,
    });
  };

  return (
    <Grid className={classes.root} container direction='column'>
      <Grid item container direction='row' justifyContent='space-between'>
        <Typography variant='h4'>
          {mealPlan.name}
          <IconButton onClick={() => handleRemoveMealPlan(mealPlan.id)} style={{ marginLeft: '4px', padding: 4 }}>
            <ClearIcon />
          </IconButton>
        </Typography>

        <Grid item className={classes.buttons} container direction='row'>
          <Button variant='contained' color='primary'>Nutrition analysis</Button>
          <Button variant='contained' color='primary' onClick={handleAddAllMealPlannerItemsToCart}>Add to cart</Button>
        </Grid>
      </Grid>
      <Grid className={classes.mealPlanItemWrapper} container direction='row' wrap='nowrap'>
        {mealPlan.recipes.length > 0 && mealPlan.recipes.map((productId, idx) => (
          <MealPlanItem key={`${productId}-${idx}`}
            recipe={productsByIdsData?.find(product => product.id === productId)}
            onRemove={handleRemoveMealPlanItem}
          />
        ))}
        {mealPlan.recipes.length === 0 && (
          <Typography variant='body1'>Empty</Typography>
        )}
      </Grid>
    </Grid>
  )
}
